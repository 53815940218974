<template>
    <div>
        <div class="div">
            <img src="https://heritagestudent.org/wp-content/uploads/2016/05/en-happ_1600x600-1.jpg" alt="">
        </div>
        <div class="width30"></div>
        <div class="minwidth">
            <div class="title">HOST APPLICATION</div>
            <div class="desc">Each year, more than 50,000 international students from 90 plus countries come to the US to
                study abroad. By hosting a NAAE international student in your home, you will start seeing the
                world
                differently. Welcome an international student into your home today!</div>
            <el-steps :active="active" finish-status="success" align-center>
                <el-step title="page 1"></el-step>
                <el-step title="page 2"></el-step>
                <el-step title="page 3"></el-step>
                <el-step title="page 4"></el-step>
            </el-steps>

            <el-form ref="form" :rules="rules" :model="form" label-width="80px" class="demo-ruleForm">
                <div class="div" v-show="active == 1">
                    <div class="width30"></div>
                    <div class="title" style="text-align: left;margin: 50px 0 20px 0;">Applicant Information</div>

                    <div class="flex-sb">
                        <el-form-item label="Name" prop="member_first_name">
                            <el-input v-model="form.member_first_name" placeholder="First" style="width: 100%;"></el-input>
                            <div class="text">(Use the registered name on driver's license.)</div>
                        </el-form-item>
                        <!-- :prop="form.ming -->
                        <el-form-item label="" prop="member_last_name">
                            <el-input v-model="form.member_last_name" style="width: 100%;margin:40px 0 0 -20px;"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex-sb">
                        <!-- :prop="form.radio -->
                        <el-form-item label="Gender" prop="gender">
                            <el-radio-group v-model="form.gender">
                                <el-radio label="male">Male</el-radio>
                                <el-radio label="female">Female</el-radio>
                            </el-radio-group>

                        </el-form-item>
                        <!-- :prop="form.rl -->
                        <el-form-item label="Date of Birth" prop="birth">
                            <el-date-picker v-model="form.birth" type="date" placeholder="(mm/dd/yyyy)"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                            <div class="text">(mm/dd/yyyy)</div>
                        </el-form-item>
                    </div>

                    <!-- :prop="form.Address1 -->
                    <el-form-item label="Address" style="width: 100%;" prop="address">
                        <el-input v-model="form.address" placeholder="Address Line 1" style="width: 100%;"></el-input>
                    </el-form-item>

                    <div class="flex-sb" style="width:100%">
                        <!-- :prop="form.city -->
                        <el-form-item label="" prop="city">
                            <el-input v-model="form.city" placeholder="City" style="width:92%"></el-input>
                        </el-form-item>
                        <!-- :prop="form.state -->
                        <el-form-item label="" prop="region">
                            <el-input v-model="form.region" placeholder="State / Province / Region"
                                style="width:92%"></el-input>
                        </el-form-item>
                        <!-- :prop="form.postal -->
                        <el-form-item label="" prop="postcode">
                            <el-input v-model="form.postcode" placeholder="Postal / Zip Code" style="width:92%"></el-input>
                        </el-form-item>
                        <!-- :prop="form.country -->
                        <el-form-item label="" prop="country">
                            <el-select v-model="form.country" style="width:100%" filterable>
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in Msg.country_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <!-- :prop="form.phone -->
                        <el-form-item label="1st Phone" prop="phone">
                            <el-input v-model="form.phone"></el-input>
                        </el-form-item>
                        <!-- :prop="form.Email -->
                        <el-form-item label="2nd Phone">
                            <el-input v-model="form.phone2"></el-input>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="Email" prop="email">
                            <el-input v-model="form.email"></el-input>
                        </el-form-item>
                        <el-form-item label="Ethnicity" prop="Ethnicity">
                            <el-select v-model="form.Ethnicity" style="width:100%" filterable>
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.ethnicity_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-form-item label="Legal Status" style="width: 100%;" prop="radio">
                        <el-radio-group v-model="form.radio">
                            <el-radio v-for="(item, index) in Msg.legal_status_list" :key="index" :label="item">{{ item
                            }}</el-radio>

                        </el-radio-group>
                    </el-form-item>
                    <div class="flex-sb">
                        <el-form-item label="The year you have started living in the US" prop="living">
                            <el-input v-model="form.living" type="number"></el-input>
                            <div class="text">(i.e. 1980)</div>
                        </el-form-item>
                        <el-form-item label="Level of Education" prop="Education">
                            <el-select v-model="form.Education" style="width:100%" filterable>
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.level_education_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="Name of Final School">
                            <el-input v-model="form.School"></el-input>
                        </el-form-item>
                        <el-form-item label="Religion" prop="Religion">
                            <el-select v-model="form.Religion" style="width:100%" filterable>
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.religion_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="Current Occupation" prop="Current">
                            <el-input v-model="form.Current"></el-input>
                            <div class="text">(Write as detail as possible)</div>
                        </el-form-item>
                        <el-form-item label="WhatsApp">
                            <el-input v-model="form.WhatsApp"></el-input>
                        </el-form-item>
                    </div>

                    <el-form-item label="Level of English" style="width: 100%;" prop="Level">
                        <el-radio-group v-model="form.Level">
                            <el-radio v-for="(item, index) in Msg.english_list" :label="item" :key="index">{{ item
                            }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div class="flex-sb">
                        <el-form-item label="Primary Language at Home" prop="Primary">
                            <el-select v-model="form.Primary" style="width:100%" filterable>
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.language_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Secondary Language at Home (if any)">
                            <el-select v-model="form.Secondary" style="width:100%" filterable>
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.language_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="flex-sb">
                        <el-form-item label="Homestay Experience? How Many Students Previously?" prop="Previously">
                            <el-select v-model="form.Previously" style="width:100%" filterable>
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in Msg.student_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Desired Student's Gender" prop="Gender">
                            <el-select v-model="form.Gender" style="width:100%" filterable>
                                <el-option :label="item.name" :value="item.id"
                                    v-for="(item, index) in Msg.desired_gender_list" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-form-item label="Desired Student Grade Group. Select every preferred grade group."
                        style="width: 100%;" prop="Desired">
                        <!-- Msg.desired_grade_list -->
                        <el-checkbox-group v-model="form.Desired">
                            <!-- <el-checkbox name="type" v-for="(item, index) in Msg.desired_grade_list" :label="item.id"
                                :key="index">{{ item.name }}</el-checkbox> -->
                                <el-checkbox name="type" v-for="item in  Msg.desired_grade_list" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
                                
                        </el-checkbox-group>
                    </el-form-item>

                    <div class="flex-sb">
                        <el-form-item label="Marital Status" prop="Marital">
                            <el-select v-model="form.Marital" style="width:100%" filterable>
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.marital_status"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Does your spouse live in the house together?"
                            v-if="form.Marital == 'Separated' || form.Marital == 'Divorced'">
                            <el-radio-group v-model="form.together">
                                <el-radio label="1">Yes</el-radio>
                                <el-radio label="2">No</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>


                    <div class="flex-sb">
                        <el-form-item label="Do you have children?" prop="children">
                            <el-radio-group v-model="form.children">
                                <el-radio label="1">Yes</el-radio>
                                <el-radio label="2">No</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="Anyone will be living in the house besides your family?" prop="Anyone">
                            <el-radio-group v-model="form.Anyone">
                                <el-radio label="1">Yes</el-radio>
                                <el-radio label="2">No</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <el-form ref="form1" :rules="rules" :model="form" label-width="80px" class="demo-ruleForm">
                <div class="div" v-show="active == 2">
                    <div class="width30"></div>
                    <div class="div" style="line-height: 30px;color: #434343; margin-bottom:30px;" v-if="!Two_show">
                        Spouse Information Section - Skipped
                        <br>
                        Click <b>"Next"</b> to go on to the next page.
                    </div>
                    <div class="div" v-if="Two_show">
                        <div class="title" style="text-align: left;">Spouse Information</div>
                        <div class="desc">* In case a spouse lives in a different location, please still fill out this
                            information.</div>
                        <div class="flex-sb">
                            <el-form-item label="Name" prop="spouse_info.first_name">
                                <el-input v-model="form.spouse_info.first_name" placeholder="First"
                                    style="width: 100%;"></el-input>
                                <div class="text">(Use the registered name on driver's license.)</div>
                            </el-form-item>
                            <!-- :prop="form.ming -->
                            <el-form-item label="" prop="spouse_info.last_name">
                                <el-input v-model="form.spouse_info.last_name"
                                    style="width: 100%;margin:40px 0 0 -20px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="flex-sb">
                            <el-form-item label="Gender" prop="spouse_info.gender">
                                <el-radio-group v-model="form.spouse_info.gender">
                                    <el-radio label="male">Male</el-radio>
                                    <el-radio label="female">Female</el-radio>
                                </el-radio-group>

                            </el-form-item>
                            <el-form-item label="Date of Birth" prop="spouse_info.date_of_birth">
                                <el-date-picker v-model="form.spouse_info.date_of_birth" type="date"
                                    placeholder="(mm/dd/yyyy)" value-format="yyyy-MM-dd">
                                </el-date-picker>
                                <div class="text">(mm/dd/yyyy)</div>
                            </el-form-item>
                        </div>
                        <div class="flex-sb">
                            <el-form-item label="Living in" prop="spouse_info.living">
                                <el-radio-group v-model="form.spouse_info.living">
                                    <el-radio label="1">in the current address</el-radio>
                                    <el-radio label="2">in a different location</el-radio>
                                </el-radio-group>

                            </el-form-item>
                            <el-form-item label="Ethnicity" prop="spouse_info.ethnicity">
                                <el-select v-model="form.spouse_info.ethnicity" style="width:100%" filterable>
                                    <el-option :label="item" :value="item" v-for="(item, index) in Msg.ethnicity_list"
                                        :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <el-form-item label="Legal Status" style="width: 100%;" prop="spouse_info.legal_status">
                            <el-radio-group v-model="form.spouse_info.legal_status">
                                <el-radio v-for="(item, index) in Msg.legal_status_list" :label="item" :key="index">{{ item
                                }}</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <div class="flex-sb">
                            <el-form-item label="The year he/​she has started living in the US"
                                prop="spouse_info.start_live_us">
                                <el-input v-model="form.spouse_info.start_live_us" type="number"></el-input>
                                <div class="text">(i.e. 1980)</div>
                            </el-form-item>
                            <el-form-item label="Level of Education" prop="spouse_info.level_education">
                                <el-select v-model="form.spouse_info.level_education" style="width:100%" filterable>
                                    <el-option :label="item" :value="item" v-for="(item, index) in Msg.level_education_list"
                                        :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="flex-sb">
                            <el-form-item label="Name of Final School" prop="spouse_info.final_school">
                                <el-input v-model="form.spouse_info.final_school"></el-input>
                            </el-form-item>
                            <el-form-item label="Religion" prop="spouse_info.religion">
                                <el-select v-model="form.spouse_info.religion" style="width:100%" filterable>
                                    <el-option :label="item" :value="item" v-for="(item, index) in Msg.religion_list"
                                        :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="flex-sb">
                            <el-form-item label="Current Occupation" prop="spouse_info.occupation">
                                <el-input v-model="form.spouse_info.occupation"></el-input>
                                <div class="text">(Write as detail as possible)</div>
                            </el-form-item>
                            <el-form-item label="Email" prop="spouse_info.email">
                                <el-input v-model="form.spouse_info.email"></el-input>
                            </el-form-item>
                        </div>

                        <el-form-item label="Level of English" style="width: 100%;" prop="spouse_info.level_english">
                            <el-radio-group v-model="form.spouse_info.level_english">
                                <el-radio v-for="(item, index) in Msg.english_list" :label="item" :key="index">{{ item
                                }}</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <div class="flex-sb">
                            <el-form-item label="Primary Language at Home" prop="spouse_info.home_language_first">
                                <el-select v-model="form.spouse_info.home_language_first" style="width:100%" filterable>
                                    <el-option :label="item" :value="item" v-for="(item, index) in Msg.language_list"
                                        :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Secondary Language at Home (If any)"
                                prop="spouse_info.home_language_second">
                                <el-select v-model="form.spouse_info.home_language_second" style="width:100%" filterable>
                                    <el-option :label="item" :value="item" v-for="(item, index) in Msg.language_list"
                                        :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
            <el-form ref="form2" :rules="rules" :model="form" label-width="80px" class="demo-ruleForm">
                <div class="div" v-show="active == 3">
                    <div class="width30"></div>
                    <div class="div" style="line-height: 30px;color: #434343; margin-bottom:30px;" v-if="!There_show">
                        Family Information Section - Skipped
                        <br>
                        Click <b>"Next"</b> to go on to the next page.
                    </div>

                    <div class="container" v-if="There_show">
                        <div class="title">Family Information</div>
                        <div class="desc">
                            Fill out information of your children even if they do NOT live in the address above.Fill out
                            information of all other family member living in the
                            address above.(ex:Homestay Student,Relatives)
                        </div>
                        <div class="flex-sb">
                            <el-form-item label="How many children?">
                                <el-select v-model="form.children3" style="width:100%" @change="Listype">
                                    <el-option :label="item.label" :value="item.value" v-for="(item, index) in options"
                                        :key="index"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="How many people living in the house besides your family?">
                                <el-select v-model="form.family3" style="width:100%" @change="Listype2">
                                    <el-option :label="item.label" :value="item.value" v-for="(item, index) in options"
                                        :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="children3" v-for="(item, index1) in childrenLength" :key="index1">
                            <div class="width30"></div>
                            <div class="title" style="text-align: left;">Child {{ index1 + 1 }}</div>
                            <div class="flex-sb">

                                <el-form-item label="Name">
                                    <el-input v-model="item.first_name" placeholder="First" style="width: 100%;"></el-input>
                                    <div class="text">(Use the registered name on driver's license.)</div>
                                </el-form-item>

                                <el-form-item label="">
                                    <el-input v-model="item.last_name"
                                        style="width: 100%;margin:40px 0 0 -20px;"></el-input>
                                </el-form-item>

                            </div>
                            <div class="flex-sb">
                                <el-form-item label="Gender">
                                    <el-radio-group v-model="item.gender">
                                        <el-radio label="male">Male</el-radio>
                                        <el-radio label="female">Female</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="Year of Birth" class="xinghao">
                                    <el-input v-model="item.birth" style="width: 100%;"></el-input>
                                    <div class="text">(i.e 1980)</div>
                                </el-form-item>
                            </div>
                            <div class="flex-sb">
                                <el-form-item label="Living in" class="xinghao">
                                    <el-radio-group v-model="item.living">
                                        <el-radio label="1">in the current address</el-radio>
                                        <el-radio label="2">in a different location</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="Current Occupation">
                                    <el-input v-model="item.occupation" style="width: 100%;"></el-input>
                                    <div class="text">(Write as detail as possible)</div>
                                </el-form-item>
                            </div>

                        </div>
                        <div class="family3" v-for="(item, index) in familyLength" :key="'the' + index">
                            <div class="width30"></div>
                            <div class="title" style="text-align: left;">Other Member{{ index + 1 }}</div>
                            <div class="flex-sb">
                                <el-form-item label="Name">
                                    <el-input v-model="item.first_name" placeholder="First" style="width: 100%;"></el-input>
                                    <div class="text">(Use the registered name on driver's license.)</div>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-input v-model="item.last_name"
                                        style="width: 100%;margin:40px 0 0 -20px;"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex-sb">
                                <el-form-item label="Gender"  class="xinghao">
                                    <el-radio-group v-model="item.gender">
                                        <el-radio label="male">Male</el-radio>
                                        <el-radio label="female">Female</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="Year of Birth"  class="xinghao">
                                    <el-input v-model="item.birth" style="width: 100%;"></el-input>
                                    <div class="text">(i.e 1980)</div>
                                </el-form-item>
                            </div>
                            <div class="flex-sb">
                                <el-form-item label="Relation"  class="xinghao">
                                    <el-select v-model="item.relation" style="width:100%">
                                        <el-option :label="item" :value="item" v-for="(item, index) in Msg.relation_list"
                                            :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Current Occupation"  class="xinghao">
                                    <el-input v-model="item.occupation" style="width: 100%;"></el-input>
                                    <div class="text">(Write as detail as possible)</div>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
            <el-form ref="form3" :rules="rules" :model="form" label-width="80px" class="demo-ruleForm">
                <div class="div" v-show="active == 4">
                    <div class="width30"></div>
                    <div class="title">House Information</div>
                    <div class="flex-sb">
                        <el-form-item label="Type of House" prop="house_info.house_type">
                            <el-radio-group v-model="form.house_info.house_type">
                                <el-radio label="Single House">Single House</el-radio>
                                <el-radio label="Town House">Town House</el-radio>
                                <el-radio label="Condominium or Apartment">Condominium or Apartment </el-radio>
                                <el-radio label="other">other</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="Ownership of House" prop="house_info.ownership">
                            <el-radio-group v-model="form.house_info.ownership">
                                <el-radio label="Own">Own</el-radio>
                                <el-radio label="Rent">Rent</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="Number of Bedroom" prop="house_info.bedroom">
                            <el-select v-model="form.house_info.bedroom" style="width:100%">
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.bedroom_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Number of Bathroom" prop="house_info.bathroom">
                            <el-select v-model="form.house_info.bathroom" style="width:100%">
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.cars_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="Number of Cars" prop="house_info.cars">
                            <el-select v-model="form.house_info.cars" style="width:100%">
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.cars_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Internet Wi-Fi Available" prop="house_info.wifi">
                            <el-radio-group v-model="form.house_info.wifi">
                                <el-radio label="1">Yes</el-radio>
                                <el-radio label="2">No</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="Current Available Bedrooms" prop="house_info.available_bedroom">
                            <el-select v-model="form.house_info.available_bedroom" style="width:100%">
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.available_room_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Available Bedrooms are Located" prop="house_info.bedroom_located">
                            <el-select v-model="form.house_info.bedroom_located" style="width:100%">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in Msg.bedroom_located"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="flex-sb">
                        <el-form-item label="Private Bathroom for the Student" prop="house_info.student_bathroom">
                            <!-- <el-input v-model="form.house_info.student_bathroom" style="width: 100%;"></el-input> -->
                            <el-radio-group v-model="form.house_info.student_bathroom">
                                <el-radio label="1">Yes</el-radio>
                                <el-radio label="2">No</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="For shared bathroom, who will be shared with?">

                            <el-input v-model="form.house_info.bathroom_shared" style="width: 100%;"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="Extra facilities or equipments allow the student to use" style="width: 100%;">
                        <el-input v-model="form.house_info.extra_use" style="width: 100%;"></el-input>
                        <div class="text">(e.g. piano, swimming pool, fitness equipment etc.)</div>
                    </el-form-item>

                    <el-form-item label="How Many Pets in the House?" style="width: 100%;" prop="house_info.pets">
                        <el-radio-group v-model="form.house_info.pets" @change="Howmany()">
                            <el-radio label="0">None</el-radio>
                            <el-radio label="1">One</el-radio>
                            <el-radio label="2">Two</el-radio>
                            <el-radio label="3">Three</el-radio>
                            <el-radio label="4">Four</el-radio>
                            <el-radio label="5">Five</el-radio>
                            <el-radio label="6">Six</el-radio>
                            <el-radio label="7">Seven</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <div class="flex-sb" style="flex-wrap:wrap">
                        <el-form-item :label="`Pet${index1 + 1}`" v-for="(item1, index1) in form.house_info.pets_json"
                            :key="index1">
                            <el-select v-model="item1.pet" style="width:100%">
                                <el-option :label="item" :value="item" v-for="(item, index) in Msg.cat_list"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="upload_video">
                        <p>Photo - Pet</p>
                        <div>
                            <label for="file9" class="label">Click Upload</label>
                            <input type="file" ref="upload" name="file" id="file9" style="display:none" @change="selectFile"
                                data-name="Pet">
                            <!-- `http://shanghe1.oss-cn-beijing.aliyuncs.com/` -->
                            <!-- <img :src="require(form.house_info.pet_photo)" alt="" style="object-fit: cover;"> -->
                            <div class="desc" style="margin-top: 10px;">Max. video size 10 MB</div>
                        </div>
                    </div>
                    <!-- 
                        调研小程序的使用流程
                        排课系统提醒功能
                    -->

                    <div class="title" style="text-align: left;">Miscellaneous</div>
                    <el-form-item label="Ride Availability?" style="width: 100%;" prop="house_info.ride_available">
                        <el-radio-group v-model="form.house_info.ride_available">
                            <el-radio label="1">No Ride Available</el-radio>
                            <el-radio label="2">School Commuting Ride Only</el-radio>
                            <el-radio label="3">School Commuting Rides + Extra Local Rides</el-radio>
                        </el-radio-group>
                        <div class="text">(This option is VERY critical to host middle or high school students)</div>
                    </el-form-item>

                    <div class="flex-sb">
                        <el-form-item label="Expected Monthly Compensation">
                            <el-input v-model="form.house_info.expected_fee"></el-input>
                            <div class="text">(e.g. piano, swimming pool, fitness equipment etc.)</div>
                        </el-form-item>

                        <el-form-item label="Hobbies and interests for Yourself and Your Family" prop="house_info.hobby">
                            <el-input v-model="form.house_info.hobby" type="textarea" :rows="2"></el-input>
                        </el-form-item>
                    </div>

                    <el-form-item label="Additional Introduction of Yourself and Your Family" style="width:100%"
                        prop="house_info.introduction">
                        <el-input v-model="form.house_info.introduction" type="textarea" :rows="3"></el-input>
                    </el-form-item>
                    <div class="House">
                        <div class="title" style="text-align: left;">House & Family Photos</div>
                        <div class="desc">Photo submission is required. Save the incomplete form and resume when photos are
                            ready.</div>
                        <div class="flex-sb" style="flex-wrap: wrap;">

                            <div class="upload_box">
                                <p>Photo - Host</p>
                                <!-- <b style="color: #ff3131;margin-left: 6px;">*</b> -->
                                <div class="width20"></div>
                                <label for="file" class="label">Upload
                                    <input type="file" ref="Host" name="file" id="file" style="display:none"
                                        @change="selectFile" data-name="Host">
                                </label>
                                <span>Max. photo size 10 MB</span>
                            </div>

                            <div class="upload_box">
                                <p>Photo - Family</p>
                                <!-- <b style="color: #ff3131;margin-left: 6px;">*</b> -->
                                <div class="width20"></div>
                                <label for="file1" class="label">Upload
                                    <input type="file" ref="Family" name="file" id="file1" style="display:none"
                                        @change="selectFile" data-name="Family">
                                </label>
                                <span>Max. photo size 10 MB</span>
                            </div>

                            <div class="upload_box">
                                <p>Photo - Available</p>
                                <!-- <b style="color: #ff3131;margin-left: 6px;">*</b> -->
                                <div class="width20"></div>
                                <label for="file2" class="label">Upload</label>
                                <input type="file" ref="Available" name="file2" id="file2" style="display:none"
                                    @change="selectFile" data-name="Available">
                                <span>Max. photo size 10 MB</span>
                            </div>

                            <div class="upload_box">
                                <p>Photo - Living</p>
                                <!-- <b style="color: #ff3131;margin-left: 6px;">*</b> -->
                                <div class="width20"></div>
                                <label for="file3" class="label">Upload</label>
                                <input type="file" ref="Living" name="file3" id="file3" style="display:none"
                                    @change="selectFile" data-name="Living">
                                <span>Max. photo size 10 MB</span>
                            </div>

                            <div class="upload_box">
                                <p>Photo - Kitchen</p>
                                <!-- <b style="color: #ff3131;margin-left: 6px;">*</b> -->
                                <div class="width20"></div>
                                <label for="file4" class="label">Upload</label>
                                <input type="file" ref="Kitchen" name="file4" id="file4" style="display:none"
                                    @change="selectFile" data-name="Kitchen">
                                <span>Max. photo size 10 MB</span>
                            </div>

                            <div class="upload_box">
                                <p>Photo - Dining</p>
                                <!-- <b style="color: #ff3131;margin-left: 6px;">*</b> -->
                                <div class="width20"></div>
                                <label for="file5" class="label">Upload</label>
                                <input type="file" ref="Dining" name="file5" id="file5" style="display:none"
                                    @change="selectFile" data-name="Dining">
                                <span>Max. photo size 10 MB</span>
                            </div>

                            <div class="upload_box">
                                <p>Photo - Exterior</p>
                                <!-- <b style="color: #ff3131;margin-left: 6px;">*</b> -->
                                <div class="width20"></div>
                                <label for="file6" class="label">Upload</label>
                                <input type="file" ref="Exterior" name="file6" id="file6" style="display:none"
                                    @change="selectFile" data-name="Exterior">
                                <span>Max. photo size 10 MB</span>
                            </div>

                            <div class="upload_box">
                                <p>Photo - Food</p>
                                <!-- <b style="color: #ff3131;margin-left: 6px;">*</b> -->
                                <div class="width20"></div>
                                <label for="file7" class="label" data-name="Food">Upload</label>
                                <input type="file" ref="Food" name="file7" id="file7" style="display:none"
                                    @change="selectFile" data-name="Food">
                                <span>Max. photo size 10 MB</span>
                            </div>

                        </div>
                    </div>

                    <div class="width30"></div>

                    <div class="video">
                        <div class="title" style="text-align: left;margin-bottom: 10px;">Video</div>
                        <div class="desc" style="margin-bottom: 30px;">Presenting a video introduction on your profile page
                            is very important, that makes
                            your profile more attractive to the student parties.</div>
                        <div class="upload_video">
                            <p>Video Introduction</p>
                            <div>
                                <label for="file8" class="label">Upload
                                    Video</label>
                                <input type="file" ref="upload" name="file" id="file8" style="display:none"
                                    @change="selectFile" data-name="Video">
                                <div class="desc" style="margin-top: 10px;">Max. video size 10 MB</div>
                            </div>
                        </div>
                    </div>

                    <div class="confirm">
                        <div class="title" style="text-align: left;">Review & Confirm</div>
                        <div class="desc">Please carefully review the information provided above and then click on the check
                            box below to confirm the information given is correct.
                        </div>

                        <el-form-item label="" prop="checkList">
                            <el-checkbox-group v-model="form.checkList">
                                <el-checkbox
                                    label="I have carefully reviewed and confirmed that the information provided above is true and correct."></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>

                    <el-form-item label="Today Date" prop="house_info.today_date">
                        <el-date-picker v-model="form.house_info.today_date" type="date" placeholder="Please select"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                        <div class="text">(mm/dd/yyyy)</div>
                    </el-form-item>
                </div>
            </el-form>
            <div class="mater">
                <!-- <div class=""> -->
                <el-button @click="back()" v-show="active !== 1">Back</el-button>
                <el-button @click="next(active)" type="primary" v-if="active !== 4">Next</el-button>
                <el-button @click="submitForm(rules)" type="primary" v-if="active == 4">Submit</el-button>
                <el-button @click="SaveData()" type="success" style="margin-left:auto">Save</el-button>
                <!-- </div> -->
            </div>
        </div>
        <div class="width80"></div>
    </div>
</template>
<script>
import { Menu, TokenOSS, DataSubmit, DataSave, HomestayHX } from '@/api/home'
import OSS from 'ali-oss'
export default {
    data() {
        var validateRadio = (rule, value, callback) => {
            if (value) {
                // 当值为0的时候当做没选择
                if (value == 0) {
                    callback(new Error('Please select Cannot be empty'))
                } else {
                    callback();
                }
            } else {
                callback(new Error('Please select Cannot be empty'));
            }
        };

        return {
            cityOptions:['上海', '北京', '广州', '深圳'],
            getOSS: {
                client: null,
                selectedFile: null,
                bucketName: 'shanghe1',
                objectPath: 'house/video/', // 文件存储路径
                stsToken: null,
                accessKeyId: "",
                accessKeySecret: "",
                region: "",
            },
            VideoLoading: '',
            Expected: "",
            // Hobbies: "",
            // introduction: "",
            Msg: "",
            childrenLength: [],
            familyLength: [],
            There_show: true,
            Two_show: true,
            rules: {
                // first_name: [
                //     { required: true, validator: validateRadio, trigger: 'blur' }
                // ],
                checkList: [{ required: true, message: 'I have carefully reviewed and confirmed that the information provided above is true and correct." must be Yes.', trigger: 'blur' }],

                house_info: {
                    bedroom: [{ required: true, message: 'Number of Bedroom is required.', trigger: 'change' }],
                    bathroom: [{ required: true, message: 'Number of Bathroom is required.', trigger: 'change' }],
                    cars: [{ required: true, message: 'Number of Cars is required.', trigger: 'change' }],
                    wifi: [{ required: true, message: 'Internet Wi-Fi Available is required.', trigger: 'change' }],
                    available_bedroom: [{ required: true, message: 'Current Available Bedrooms is required.', trigger: 'change' }],
                    bedroom_located: [{ required: true, message: 'Available Bedrooms are Located is required.', trigger: 'change' }],
                    student_bathroom: [{ required: true, message: 'Private Bathroom for the Student is required.', trigger: 'change' }],
                    pets: [{ required: true, message: 'How Many Pets in the House? is required.', trigger: 'change' }],

                    Availability: [{ required: true, message: 'Ride Availability is required.', trigger: 'change' }],
                    hobby: [{ required: true, message: 'Hobbies and interests for Yourself and Your Family is required.', trigger: 'blur' }],
                    introduction: [{ required: true, message: 'Additional Introduction of Yourself and Your Family is required.', trigger: 'blur' }],
                    today_date: [{ required: true, message: 'Today Date is required.', trigger: 'blur' }],
                    house_type: [{
                        required: true, message: 'Type of House is required.', trigger: 'change'
                    }],
                    ownership: [{
                        required: true, message: 'Ownership of House is required', trigger: 'change'
                    }],
                },


                Primary: [{
                    required: true, message: 'is required.', trigger: 'change'
                }],
                Previously: [{
                    required: true, message: 'Homestay Experience? How Many Students Previously? is required.', trigger: 'blur'
                }],
                gender: [{ required: true, message: 'Gender is required.', trigger: 'change' }],
                children: [{
                    required: true, validator: validateRadio, trigger: 'blur'
                }],
                Anyone: [{
                    required: true, validator: validateRadio, trigger: 'blur'
                }],

                Level: [{
                    required: true, message: 'Level of English is required', trigger: 'blur'
                }],
                Marital: [{
                    required: true, message: 'Marital Status is required.', trigger: 'change'
                }],
                living: [{
                    required: true, message: 'The year you have started living in the US is required.', trigger: 'change'
                }],
                Religion: [{
                    required: true, message: 'Religion is required.', trigger: 'blur'
                }],
                spouse_info: {
                    final_school: [{
                        required: true, message: 'Name of Final School is required.', trigger: 'blur'
                    }],
                    legal_status: [{
                        required: true, validator: validateRadio, trigger: 'change'
                    }],
                    home_language_first: [{
                        required: true, message: 'Primary Language at Home is required.', trigger: 'change'
                    }],

                    home_language_second: [{
                        required: true, message: 'Secondary Language at Home is required.', trigger: 'change'
                    }],
                    level_english: [{
                        required: true, validator: validateRadio, trigger: 'change'
                    }],
                    first_name: [{ required: true, message: 'First required.', trigger: 'blur' }],
                    last_name: [{ required: true, message: 'Last required.', trigger: 'blur' }],
                    marital: [{
                        required: true, message: 'Marital Status is required.', trigger: 'change'
                    }],
                    start_live_us: [{ required: true, message: 'The year you have started living in the US is required.', trigger: 'change' }],

                    Religion: [{
                        required: true, message: 'Religion is required.', trigger: 'blur'
                    }],
                    gender: [{ required: true, message: 'Gender is required.', trigger: 'change' }],
                    date_of_birth: [{ required: true, message: 'Date of Birth is required.', trigger: 'blur' }],
                    email: [
                        { required: true, message: 'Email is required.', trigger: 'blur' },
                    ],
                    ethnicity: [
                        { required: true, message: 'Level of Education is required.', trigger: 'change' },
                    ],
                    legal_status: [{
                        required: true, message: 'Legal Status is required.', trigger: 'change'
                    }],
                    occupation: [
                        {
                            required: true, message: 'Current Occupation is required.', trigger: 'blur'
                        }
                    ],
                    religion: [{
                        required: true, message: 'Religion is required.', trigger: 'change'
                    }],

                    desired: [{
                        required: true, message: 'Please select Desired Student Grade Group', trigger: 'change'
                    }],
                    start_live_us: [{
                        required: true, message: 'The year he/she has started living in the US is required.', trigger: 'change'
                    }],
                    level_education: [{
                        required: true, message: 'Level of Education is required.', trigger: 'change'
                    }],
                },

                member_first_name: [{ required: true, message: 'First required.', trigger: 'blur' }],
                member_last_name: [{ required: true, message: 'Last required.', trigger: 'blur' }],
                Gender: [{ required: true, message: 'Desired Student Gender is required.', trigger: 'change' }],
                gender: [{ required: true, message: 'Gender is required.', trigger: 'change' }],
                birth: [{ required: true, message: 'Date of Birth is required.', trigger: 'blur' }],
                email: [{ required: true, message: 'Email is required.', trigger: 'blur' },],

                phone: [
                    { required: true, message: '1st Phone is required.', trigger: 'blur' },
                ],
                address: [{
                    required: true, message: 'Address Line 1, City, State and Zip Code are required.', trigger: 'blur'
                },],
                city: [
                    { required: true, message: 'City, State, Postal / Zip Code and Country are required.', trigger: 'blur' },
                ],
                region: [
                    { required: true, message: 'State, Postal / Zip Code and Country are required.', trigger: 'blur' },
                ],
                postcode: [
                    { required: true, message: 'Postal / Zip Code and Country are required.', trigger: 'blur' },
                ],
                country: [
                    { required: true, message: 'Country is required.', trigger: 'change' },
                ],

                current: [
                    {
                        required: true, message: 'Current Occupation is required.', trigger: 'blur'
                    }
                ],
                Ethnicity: [
                    { required: true, message: 'Level of Education is required.', trigger: 'change' },
                ],

                radio: [{
                    required: true, validator: validateRadio, trigger: 'change'
                }],
                Religion: [{
                    required: true, message: 'Religion is required.', trigger: 'change'
                }],

                Desired: [{
                    required: true, message: 'Please select Desired Student Grade Group', trigger: 'change'
                }],
            },

            Marital: [{
                id: 1,
                name: "Married",
            }, {
                id: 2,
                name: "Single",
            }, {
                id: 3,
                name: "Divorced",
            }, {
                id: 4,
                name: "Separated",
            }, {
                id: 5,
                name: "Widowed",
            }],
            options: [{
                value: 0,
                label: 'None'
            }, {
                value: 1,
                label: 'One'
            }, {
                value: 2,
                label: 'Two'
            }, {
                value: 3,
                label: 'There'
            }, {
                value: 4,
                label: 'Four'
            }, {
                value: 5,
                label: 'Five'
            }, {
                value: 6,
                label: 'Six'
            }, {
                value: 7,
                label: 'Seven'
            }, {
                value: 8,
                label: 'Eight'
            }, {
                value: 9,
                label: 'Nine'
            }, {
                value: 10,
                label: 'Ten'
            }],
            active: 1,
            form: {
                // king:["Kindergarten","1st-3rd","4th - 5th"],
                shared: "",
                Gender: "",
                house_info: {
                    house_type: "",
                    ownership: "",
                    bedroom: "",
                    bathroom: "",
                    cars: "",
                    wifi: "",
                    available_bedroom: "",
                    bedroom_located: "",
                    student_bathroom: "",
                    extra_use: "",
                    pets: "0",
                    pet_photo: "", //宠物图片路径
                    bathroom_shared: "",
                    ride_available: "",
                    expected_fee: "",
                    hobby: "",
                    introduction: "",
                    photo_host: "",
                    photo_family: "",
                    photo_available_room: "",
                    photo_living_room: "",
                    photo_kitchen: "",
                    photo_dining_room: "",
                    photo_exterior: "",
                    photo_food: "",
                    video_introduction: "",
                    today_date: "",
                    pets_json: []
                },
                checkList: [],
                children3: "",
                family3: "",
                spouse_info: {
                    legal_status: "",
                    ethnicity: "",
                    level_education: "",
                    final_school: "",
                    first_name: "",
                    last_name: "",
                    gender: "",
                    date_of_birth: "",
                    start_live_us: "",
                    living: "",
                    occupation: "",
                    email: "",
                    religion: "",
                    level_english: "",
                    home_language_first: "",
                    home_language_second: "",
                    start_live_us: "",
                },
                Education: "",
                together: "",
                Religion: "",
                School: "",
                children: "",
                Anyone: "",
                Marital: "",
                Desired: [],
                Primary: "",
                Secondary: "",
                Previously: "",
                Level: "",
                Current: "",
                WhatsApp: "",
                radio: "",
                living: "",
                member_first_name: "",
                member_last_name: "",
                gender: "",
                birth: "",
                address: "",
                city: "",
                region: "",
                postcode: "",
                country: "",
                phone: "",
                phone2: "",
                Ethnicity: "",
                email: "",
            },

        }
    },
    mounted() {
        this.HandleMsg();
        this.GetOssToken();
        this.HandleData()
    },
    methods: {
        HandleData() {
            var id = this.$route.query.id;
            if (id) {
                HomestayHX({ id: id }).then(res => {
                    if (res.code == 200) {
                        this.form.member_first_name = res.data.app_first_name;
                        this.form.member_last_name = res.data.app_last_name,
                            this.form.gender = res.data.gender,
                            this.form.birth = res.data.date_of_birth,
                            this.form.address = res.data.address,
                            this.form.city = res.data.city,
                            this.form.region = res.data.region,
                            this.form.postcode = res.data.zip_code,
                            this.form.country = res.data.country,
                            this.form.phone = res.data.phone_first,
                            this.form.phone2 = res.data.phone_second,
                            this.form.email = res.data.email,
                            this.form.Ethnicity = res.data.ethnicity,
                            this.form.radio = res.data.legal_status,
                            this.form.living = res.data.start_live_us,
                            this.form.Education = res.data.level_education,
                            this.form.School = res.data.final_school,
                            this.form.Religion = res.data.religion,
                            this.form.Current = res.data.occupation,
                            this.form.WhatsApp = res.data.whats_app,
                            this.form.Level = res.data.level_english,
                            this.form.Primary = res.data.home_language_first,
                            this.form.Secondary = res.data.home_language_second,
                            this.form.Previously = res.data.students,
                            this.form.Gender = res.data.desired_gender,
                            this.form.Desired = res.data.desired_grade,
                            this.form.Marital = res.data.marital_status,
                            this.form.together = res.data.live_together,
                            this.form.children = res.data.have_children.toString(),
                            this.form.Anyone = res.data.besides_family.toString(),
                            this.form.spouse_info = res.data.spouse,
                            this.form.spouse_info.living =res.data.spouse.living.toString(),
                            this.form.house_info = res.data.house,
                            this.childrenLength = res.data.child,
                            this.familyLength = res.data.member
                    }
                });
            }
        },
        // 保存当前数据
        SaveData() {
            var id = this.$route.query.id;
            let { member_first_name, member_last_name, gender, Gender, birth, address, city, region, postcode, country, phone, phone2, Ethnicity, email,
                Education, together, Religion, School, children, Anyone, Marital, Desired, Primary, Secondary, Previously, Level, Current, WhatsApp, radio, living, spouse_info
                , house_info } = this.form;
            DataSave({
                app_first_name: member_first_name, app_last_name: member_last_name, gender: gender, date_of_birth: birth, address: address, city: city,
                region: region, zip_code: postcode, country: country, phone_first: phone, phone_second: phone2, email: email, ethnicity: Ethnicity, legal_status: radio,
                start_live_us: living, level_education: Education, final_school: School, religion: Religion, occupation: Current, whats_app: WhatsApp, level_english: Level,
                home_language_first: Primary, home_language_second: Secondary, students: Previously, desired_gender: Gender, desired_grade: Desired, marital_status: Marital,
                live_together: together, have_children: children, besides_family: Anyone, spouse_info: spouse_info, house_info: house_info, child_info: this.childrenLength,
                member_info: this.familyLength, id: id
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success("Saved successfully");
                    this.$router.replace({
                        path: "/host-application",
                        query: { id: res.data.id }
                    })
                }
            });
        },
        GetOssToken() {
            TokenOSS().then(res => {
                console.log(res, 22)
                this.getOSS.accessKeyId = res.data.Credentials.AccessKeyId;
                this.getOSS.accessKeySecret = res.data.Credentials.AccessKeySecret;
                this.getOSS.stsToken = res.data.Credentials.SecurityToken;
                this.getOSS.region = 'oss-cn-beijing';
            });
        },
        selectFile(event) {
            var dataname = event.target.dataset.name;
            this.getOSS.selectedFile = event.target.files[0];
            this.VideoLoading = this.$loading({
                lock: true,
                text: 'Up pass...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.uploadFile(dataname);
        },

        async uploadFile(dataname) {
            console.log(dataname, 22);

            if (!this.getOSS.selectedFile) {
                return;
            }

            const { accessKeyId, accessKeySecret, region, stsToken } = this.getOSS;
            // return false;
            this.getOSS.client = new OSS({
                accessKeyId,
                accessKeySecret,
                region,
                stsToken,
                bucket: this.getOSS.bucketName,
            });

            try {
                const objectName = this.getOSS.objectPath + this.form.member_first_name + this.form.member_last_name + this.getOSS.selectedFile.name;
                if (dataname == "Host") {
                    this.form.house_info.photo_host = objectName;
                }
                if (dataname == "Family") {
                    this.form.house_info.photo_family = objectName;
                }

                if (dataname == "Available") {
                    this.form.house_info.photo_available_room = objectName;
                }
                if (dataname == "Living") {
                    this.form.house_info.photo_living_room = objectName;
                }
                if (dataname == "Kitchen") {
                    this.form.house_info.photo_kitchen = objectName;
                }
                if (dataname == "Dining") {
                    this.form.house_info.photo_dining_room = objectName;
                }
                if (dataname == "Exterior") {
                    this.form.house_info.photo_exterior = objectName;
                }
                if (dataname == "Food") {
                    this.form.house_info.photo_food = objectName;
                }
                if (dataname == "Pet") {
                    // setTimeout(function(){
                    this.form.house_info.pet_photo = objectName;
                    // },300);
                }
                if (dataname == "Video") {
                    this.form.house_info.video_introduction = objectName;
                }
                // console.log(this.form.photo.Host, 8888)
                // console.log(this.VideoUrl1, this.VideoUrl2)
                await this.getOSS.client.put(objectName, this.getOSS.selectedFile);
                console.log('文件上传成功：', objectName);
                this.$message({
                    message: 'Congratulations, Upload successfully',
                    type: 'success'
                });
                this.VideoLoading.close();
            } catch (error) {
                console.error('File upload failure:', error);
                this.$message.error('file upload failed, try to reopen the page');
                this.VideoLoading.close();
            }
        },
        HandleMsg() {
            Menu().then(res => {
                if (res.code == 200) {
                    this.Msg = res.data;
                }
            });
        },
        Howmany() {
            this.form.house_info.pets_json = [];
            for (var i = 1; i <= this.form.house_info.pets; i++) {
                this.form.house_info.pets_json.push({
                    pet: "",
                })
            }
        },
        Listype() {
            this.childrenLength = [];
            for (var i = 1; i <= this.form.children3; i++) {
                this.childrenLength.push({
                    first_name: "",
                    last_name: "",
                    gender: "",
                    birth: "",
                    living: "",
                    occupation: "",
                })
            }
        },

        Listype2() {
            this.familyLength = [];
            for (var i = 1; i <= this.form.family3; i++) {
                this.familyLength.push({
                    first_name: "",
                    last_name: "",
                    gender: "",
                    birth: "",
                    relation: "",
                    occupation: "",
                })
            }
        },
        submitForm() {
            var id = this.$route.query.id;
            let { member_first_name, member_last_name, gender, Gender, birth, address, city, region, postcode, country, phone, phone2, Ethnicity, email,
                Education, together, Religion, School, children, Anyone, Marital, Desired, Primary, Secondary, Previously, Level, Current, WhatsApp, radio, living, spouse_info
                , house_info } = this.form;
            this.$refs.form3.validate((valid) => {
                if (valid) {
                    DataSubmit({
                        app_first_name: member_first_name, app_last_name: member_last_name, gender: gender, date_of_birth: birth, address: address, city: city,
                        region: region, zip_code: postcode, country: country, phone_first: phone, phone_second: phone2, email: email, ethnicity: Ethnicity, legal_status: radio,
                        start_live_us: living, level_education: Education, final_school: School, religion: Religion, occupation: Current, whats_app: WhatsApp, level_english: Level,
                        home_language_first: Primary, home_language_second: Secondary, students: Previously, desired_gender: Gender, desired_grade: Desired, marital_status: Marital,
                        live_together: together, have_children: children, besides_family: Anyone, spouse_info: spouse_info, house_info: house_info, child_info: this.childrenLength,
                        member_info: this.familyLength,id:id
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success("The data was submitted successfully");
                        }
                    });
                }

            })
        },
        next(num) {
            if (num == 1) {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.active = 2;
                        if (this.form.Marital == "Single" || this.form.Marital == "Divorced" && this.form.together == 2 || this.form.Marital == "Separated" && this.form.together == 2 || this.form.Marital == "Widowed") {
                            this.Two_show = false;
                            this.form.spouse_info = {};
                        } else {
                            this.Two_show = true;
                        }
                    }
                })
            }

            if (num == 2) {
                this.$refs.form1.validate((valid) => {
                    if (valid) {
                        this.active = 3;
                        if (this.form.children == 2 && this.form.Anyone == 2) {
                            this.There_show = false;
                        } else {
                            this.There_show = true;
                        }
                    }
                })
            }

            if (num == 3) {
                for (var i = 0; i < this.childrenLength.length; i++) {
                    if (this.childrenLength[i].birth == "" || this.childrenLength[i].living == "") {
                        this.$message.error('Required * cannot be empty');
                        return;
                    }
                }
                for (var i = 0; i < this.familyLength.length; i++) {
                    if (this.familyLength[i].relation == "" || this.familyLength[i].occupation == "" || this.familyLength[i].gender == "" || this.familyLength[i].occupation == "") {
                        this.$message.error('Required * cannot be empty');
                        return;
                    }
                }
                this.active = 4;
            }

            // if (this.active++ > 4) this.active = 0;

        },
        back() {
            this.active--
        },
    },
}
</script>


<style lang="scss" scoped>
.xinghao{
    ::v-deep .el-form-item__label::after{
        content: "*";
        color:#ff3131;
        margin-left: 6px;
    }
}
.mater {
    display: flex;
}

.width20 {
    width: 100%;
    height: 20px;
}

.text {
    color: #C0C4CC;
}

.upload_video {
    img {
        width: 200px;
        height: 100px;
    }

    .label {
        color: #D85427;
        border: 1px solid #D85427;
        padding: 4px 10px;
        text-align: center;
        border-radius: 10px;
    }
}

.upload_box {
    width: 48%;

    .label {
        color: #D85427;
        border: 1px solid #D85427;
        padding: 4px 10px;
        text-align: center;
        border-radius: 10px;
    }

    p {
        font-weight: bold;
    }

    span {
        display: block;
        font-size: 12px;
        color: #777;
        letter-spacing: 1px;
        margin: 16px 0 16px 0;
    }
}

.upload_video p {
    font-weight: bold;
    margin-bottom: 10px;
}

.width80 {
    width: 100%;
    height: 80px;
}

.div {
    img {
        width: 100%;
        height: 400px;
        // width: 200px;
        // height: 200px;
        object-fit: cover;
        // margin: 20px 0;
        // display: block;
    }
}

::v-deep .el-form-item__content {
    margin-left: 0px !important;
}

::v-deep .el-form-item__label {
    float: none;
    font-weight: bold;
}

::v-deep .el-input {
    // width: 40%;
    margin-right: 20px;
}

::v-deep .el-form-item {
    width: 48%;
    // display: inline-block;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
}

.width30 {
    width: 100%;
    height: 30px;
}

.minwidth {
    width: 900px;
    margin: 0 auto;

    .title {
        font-size: 18px;
        letter-spacing: 1px;
        text-align: center;
        color: #333;
        margin-bottom: 30px;
        font-weight: bold;
    }

    .desc {
        margin-bottom: 50px;
        font-size: 12px;
        color: #777;
        letter-spacing: 1px;
    }
}
</style>